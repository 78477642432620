var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-text-field',{attrs:{"error-messages":_vm.fieldErrors.amount,"label":"Anzahl","hint":_vm.hint.amount,"rules":_vm.validationErrors.amount,"validate-on-blur":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=_vm._n($$v)},expression:"amount"}},[(
        !_vm.reference &&
        this.referenceData.amount
      )?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter(`amount`)}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-text-field',{attrs:{"error-messages":_vm.fieldErrors.volumeFlow,"label":"Luftmenge","suffix":"m³/h","hint":_vm.hint.volume_flow,"rules":_vm.validationErrors.volume_flow,"validate-on-blur":""},model:{value:(_vm.volumeFlow),callback:function ($$v) {_vm.volumeFlow=_vm._n($$v)},expression:"volumeFlow"}},[(
        !_vm.reference &&
        this.referenceData.volume_flow
      )?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter(`volume_flow`)}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-text-field',{attrs:{"error-messages":_vm.fieldErrors.totalPressure,"label":"Gesamtdruck","suffix":"Pa","hint":_vm.hint.total_pressure,"rules":_vm.validationErrors.total_pressure,"validate-on-blur":""},model:{value:(_vm.totalPressure),callback:function ($$v) {_vm.totalPressure=_vm._n($$v)},expression:"totalPressure"}},[(
        !_vm.reference &&
        this.referenceData.total_pressure
      )?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter(`total_pressure`)}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-text-field',{attrs:{"error-messages":_vm.fieldErrors.uptime,"label":"Jährliche Betriebsstunden","suffix":"h","hint":_vm.hint.uptime,"rules":_vm.validationErrors.uptime,"validate-on-blur":""},model:{value:(_vm.uptime),callback:function ($$v) {_vm.uptime=_vm._n($$v)},expression:"uptime"}},[(
        !_vm.reference &&
        this.referenceData.uptime
      )?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter(`uptime`)}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),(_vm.reference)?_c('v-text-field',{attrs:{"error-messages":_vm.fieldErrors.yearBuilt,"label":"Baujahr","hint":_vm.hint.year_built,"rules":_vm.validationErrors.year_built,"validate-on-blur":""},model:{value:(_vm.yearBuilt),callback:function ($$v) {_vm.yearBuilt=_vm._n($$v)},expression:"yearBuilt"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }