import Vue from "vue"
import store from "../store"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import About from "../views/About.vue"
import ProjectList from "../views/ProjectList.vue"
import ProjectCalc from "@/views/ProjectCalc";
import Action from "@/views/Action";
import Login from "@/views/Login";
import Register from "@/views/Register";
import Imprint from "@/views/Imprint";
import Contact from "@/views/Contact";
import ProjectDetails from "@/views/ProjectDetails";
import ProjectDashboard from "@/views/ProjectDashboard";
import ForgotPassword from "../views/ForgotPassword.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/login/forgot-Password/",
    name: "ForgotPassword",
    component: ForgotPassword
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/projects",
    name: "ProjectList",
    component: ProjectList
  },{
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/project/new",
    name: "NewProject",
    component: ProjectDetails
  },
  {
    path: "/project/:projectId/details",
    name: "ProjectDetails",
    component: ProjectDetails
  },
  {
    path: "/project/:projectId",
    name: "ProjectDashboard",
    component: ProjectDashboard
  },
  {
    path: "/project/:projectId/action/new",
    name: "NewAction",
    component: Action
  },
  {
    path: "/project/:projectId/action/:actionId",
    name: "Action",
    component: Action
  },
  {
    path: "/project/:projectId/calc",
    name: "ProjectCalc",
    component: ProjectCalc
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_ROUTER_BASE_URL,
  routes
})

router.afterEach(() => {
  store.commit("resetTitle")
})

export default router
