import Vue from "vue"
import Vuex from "vuex"
import axios from "axios";



Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        currentProject: null,
        currentProjectActions: null,
        currentProjectActionGroups: null,
        allProjects: {},
        user: null,
        title: null,
        noCalc: [],
        noCalcGroup: [],
        navigationMini: false,
        forgotPassword: false,
    },
    getters: {
        isLoggedIn(state) {
            return !!state.user
        },
        userId(state) {
            return state.user?.id
        },
        getNavigationMini(state) {
            return state.navigationMini
        },
        getForgotPassword(state) {
            return state.forgotPassword
        }
    },
    mutations: {
        setAllProjects(state, projects) {
            state.allProjects = projects.reduce((res, project) => {
                res[project.id] = project
                return res
            }, {})
        },
        setCurrentProject(state, project) {
            state.currentProject = project
        },
        setCurrentProjectActions(state, actions) {
            state.currentProjectActions = actions
        },
        setCurrentProjectActionGroups(state, groups) {
            state.currentProjectActionGroups = groups
        },
        setUser(state, user) {
            state.user = user;
        },
        setForgotPassword(state, forgotPassword) {
            state.forgotPassword = forgotPassword;
        },
        setTitle(state, title) {
            state.title = title || null;
        },
        resetTitle(state) {
            state.title = null;
        },
        initialiseNoCalc(state) {
            if (localStorage.getItem("noCalc")) {
                state.noCalc = JSON.parse(localStorage.noCalc)
            }
        },
        initialiseNoCalcGroup(state) {
            if (localStorage.getItem("noCalcGroup")) {
                state.noCalcGroup = JSON.parse(localStorage.noCalcGroup)
            }
        },
        SET_NAVIGATIONMINI(state, mini) {
            state.navigationMini = mini;
        },
    },
    actions: {
        fetchAllProjects({commit}) {
            axios.get("/api/v1/projects/").then(response => {

                commit("setAllProjects", response.data)
            }).catch(() => {
                //TODO: Error handling?
            })
        },

        fetchCurrentProject({state, commit}, projectId) {
            return new Promise((resolve, reject) => {

                let fetchProjectPromise = axios.get(`/api/v1/projects/${projectId}/`)
                    .then(response => {
                        commit("setCurrentProject", response.data)
                    }).catch(() => {
                    })
                let fetchActionsPromise = axios.get("/api/v1/actions/",
                    {params: {project: projectId}})
                    .then(response => {
                        commit("setCurrentProjectActions", response.data)
                    }).catch(() => {
                    })
                let fetchActionGroupsPromise = axios.get("/api/v1/actiongroups/",
                    {params: {project: projectId}})
                    .then(response => {
                        commit("setCurrentProjectActionGroups", response.data)
                        console.log(response.data)
                    }).catch(() => {
                    })
                Promise.all([fetchProjectPromise, fetchActionsPromise, fetchActionGroupsPromise])
                    .then(() => {
                        resolve()
                    }).catch(() => reject())
            })
        },
        login({commit,state}, data) {
            return new Promise((resolve, reject) => {
                axios.post("/api/v1/auth/login/", data)
                    .then(response => {
                        commit("setForgotPassword", response.data.forget_password)
                        
                        if(state.forgotPassword){
                            console.log("forgot password")
                            console.log('user',state.user)
                        }else{
                            commit("setUser", response.data)
                            console.log(response.data)
                        }
                        
                        
                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })

            })

        },
        logout({commit}) {
            return new Promise((resolve, reject) => {
                axios.post("/api/v1/auth/logout/")
                    .then(response => {
                        resolve()
                        location.reload()
                    })
                    .catch(error => {
                        reject(error)
                    })

            })

        },
        checkSession({commit}) {
            return new Promise((resolve, reject) => {
                axios.get("/api/v1/auth/self/")
                    .then(response => { 
                        commit("setUser", response.data)
                        resolve()
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        set_navigationMini(context, mini) {
            context.commit("SET_NAVIGATIONMINI", mini);
        }
    },
    modules: {}
})
