<template>
  <v-row justify="center" no-gutters>
    <v-card>
      <v-card-title class="heading justify-center">
        <h4 class="text-h4">Registrieren</h4>
      </v-card-title>
      <v-divider/>

      <v-card-text>
        <v-form @submit.prevent="register" v-model="valid" ref="form">

          <v-text-field v-model="firstName" :rules="requiredRule" validate-on-blur label="Vorname"/>
          <v-text-field v-model="lastName" :rules="requiredRule" validate-on-blur label="Nachname"/>
          <v-text-field v-model="email" type="email" :rules="emailRules" validate-on-blur label="E-Mail"/>
          <v-text-field v-model="password" type="password" :rules="passwordRules" validate-on-blur label="Passwort" @input="validate"/>
          <v-text-field v-model="password2" type="password" :rules="password2Rules" validate-on-blur label="Passwort (wiederholen)" @input="validate"/>

          <div class="d-flex flex justify-center mt-4">
            <v-btn type="submit" color="primary" :disabled="!valid">Registrieren</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showSuccessDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Registrierung erfolgreich</v-card-title>
        <v-card-text>
          <p>Vielen Dank für Ihre Registrierung. Ihr Konto muss nun von einem Administrator freigeschaltet werden.</p>
          <p>Um den Prozess zu beschleunigen, können Sie eine E-Mail an 
            <a href="mailto:earns@rom-technik.de">earns@rom-technik.de</a> senden.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="proceedToLogin">Verstanden</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {emailMatch} from "@/utils";
import axios from "axios";

export default {
  name: "Register",
  data() {
    return {
      passwordRules: [],
      password2Rules: [],
      requiredRule: [v => !!v || "Diese Feld muss ausgefüllt sein."],
      emailRules: [],
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      password2: null,
      valid: false,
      showSuccessDialog: false,
      emailErrors: {},
      passwordErrors: {}
    }
  },
  computed: {},
  beforeMount() {
    this.$store.state.forgotPassword = false;
  },
  mounted() {
    this.password2Rules = [
     ...this.requiredRule,
      v =>
          v && v.length >= 8 || "Das Password muss mindestens 8 Zeichen lang sein.",
      () => this.password === this.password2 || "Die Passwörter stimmen nicht überein.",

    ]

    this.passwordRules = [...this.password2Rules,
      v => this.passwordErrors[v]?.[0] || true,
    ]

    this.emailRules = [
      v => (v && emailMatch(v)) || "Bitte geben Sie eine gültige E-Mail Adresse ein.",
        v => this.emailErrors[v]?.[0] || true
    ]
  },
  methods: {
    validate() {
      this.$refs.form.validate()
    },
    proceedToLogin() {
      this.showSuccessDialog = false
      this.$router.push({name: "Login"})
    },
    register() {
      let payload = {email: this.email, password: this.password, first_name: this.firstName, last_name: this.lastName}
      axios.post("/api/v1/auth/register/", payload)
          .then(response => {
            this.$toast("Registrierung erfolgreich.", {"color": "success"})
            this.showSuccessDialog = true
          })
          .catch(error => {
            if (error.response.status === 400 ) {
              if (error.response.data.email) {
                this.emailErrors[payload.email] = error.response.data.email
              }
              if (error.response.data.password) {
                this.passwordErrors[payload.password] = error.response.data.password
              }
              this.validate()
            }


            else {
              this.$toast("Es gab einen Fehler beim registrieren.", {"color": "error"})
            }
          })


    }
  }
}
</script>

<style scoped>
.v-card {
  width: 100%;
  max-width: 400px;
}
</style>