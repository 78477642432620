import { render, staticRenderFns } from "./StatsPrice.vue?vue&type=template&id=95eae410&scoped=true"
import script from "./StatsPrice.vue?vue&type=script&lang=js"
export * from "./StatsPrice.vue?vue&type=script&lang=js"
import style0 from "./StatsPrice.vue?vue&type=style&index=0&id=95eae410&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95eae410",
  null
  
)

export default component.exports