var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.fieldErrors.amount,"label":"Anzahl","hint":_vm.hint.amount,"rules":_vm.validationErrors.amount,"validate-on-blur":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=_vm._n($$v)},expression:"amount"}},[(
        !_vm.reference &&
        this.referenceData.amount
      )?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter(`amount`)}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.fieldErrors.electricity,"label":"Stromverbrauch","suffix":"kWh/a","hint":_vm.hint.electricity,"rules":_vm.validationErrors.electricity,"validate-on-blur":""},model:{value:(_vm.electricity),callback:function ($$v) {_vm.electricity=_vm._n($$v)},expression:"electricity"}},[(
        !_vm.reference &&
        this.referenceData.electricity
      )?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter(`electricity`)}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.fieldErrors.heat,"label":"Wärmeverbrauch","suffix":"kWh/a","hint":_vm.hint.heat,"rules":_vm.validationErrors.heat,"validate-on-blur":""},model:{value:(_vm.heat),callback:function ($$v) {_vm.heat=_vm._n($$v)},expression:"heat"}},[(
        !_vm.reference &&
        this.referenceData.heat
      )?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter(`heat`)}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.fieldErrors.cold,"label":"Kälteverbrauch","suffix":"kWh/a","hint":_vm.hint.cold,"rules":_vm.validationErrors.cold,"validate-on-blur":""},model:{value:(_vm.cold),callback:function ($$v) {_vm.cold=_vm._n($$v)},expression:"cold"}},[(
        !_vm.reference &&
        this.referenceData.cold
      )?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter(`cold`)}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }