<template>
  <v-form @submit.prevent="" ref="form">
    <v-text-field
      v-model.number="amount"
      :error-messages="fieldErrors.amount"
      label="Anzahl"
      :hint="hint.amount"
      :rules="validationErrors.amount"
      validate-on-blur
    >
      <v-btn
        v-if="
          !reference &&
          this.referenceData.amount
        "
        @click="getReferenceParameter(`amount`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>
    <h2 class="mt-3">Zuluft</h2>
    <v-text-field
      v-model.number="volumeFlow"
      type="number"
      :error-messages="fieldErrors.volumeFlow"
      label="Volumenstrom"
      suffix="m³/h"
      :hint="hint.volume_flow"
      :rules="validationErrors.volume_flow"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.volume_flow"
        @click="getReferenceParameter(`volume_flow`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="volumeFlowDrawDown"
      type="number"
      :error-messages="fieldErrors.volumeFlowDrawDown"
      label="Absenkvolumenstrom"
      suffix="m³/h"
      v-show="drawDownOperation"
      :hint="hint.volume_flow_draw_down"
      :rules="validationErrors.volume_flow_draw_down"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.volume_flow_draw_down"
        @click="getReferenceParameter(`volume_flow_draw_down`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="pressureSup"
      type="number"
      :error-messages="fieldErrors.pressureSup"
      label="Druckverlust"
      suffix="Pa"
      v-show="!sharedData.external_fan_calculation"
      :hint="hint.pressure_sup"
      :rules="validationErrors.pressure_sup"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.pressure_sup"
        @click="getReferenceParameter(`pressure_sup`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="etaSupPercentage"
      type="number"
      :error-messages="fieldErrors.etaSup"
      label="Systemwirkungsgrad"
      suffix="%"
      v-show="!sharedData.external_fan_calculation"
      :hint="hint.eta_sup"
      :rules="validationErrors.eta_sup"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.eta_sup"
        @click="getReferenceParameter(`eta_sup`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <h2 class="mt-5">Abluft</h2>
    <v-text-field
      v-model.number="volumeFlowEta"
      type="number"
      :error-messages="fieldErrors.volumeFlowEta"
      label="Volumenstrom"
      suffix="m³/h"
      :hint="hint.volume_flow_eta"
      :rules="validationErrors.volume_flow_eta"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.volume_flow_eta"
        @click="getReferenceParameter(`volume_flow_eta`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="volumeFlowDrawDownEta"
      type="number"
      :error-messages="fieldErrors.volumeFlowDrawDownEta"
      label="Absenkvolumenstrom"
      suffix="m³/h"
      v-show="drawDownOperation"
      :hint="hint.volume_flow_draw_down_eta"
      :rules="validationErrors.volume_flow_draw_down_eta"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.volume_flow_draw_down_eta"
        @click="getReferenceParameter(`volume_flow_draw_down_eta`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="pressureEta"
      type="number"
      :error-messages="fieldErrors.pressureEta"
      label="Druckverlust"
      suffix="Pa"
      v-show="!sharedData.external_fan_calculation"
      :hint="hint.pressure_eta"
      :rules="validationErrors.pressure_eta"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.pressure_eta"
        @click="getReferenceParameter(`pressure_eta`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="etaEtaPercentage"
      type="number"
      :error-messages="fieldErrors.etaEta"
      label="Systemwirkungsgrad"
      suffix="%"
      v-show="!sharedData.external_fan_calculation"
      :hint="hint.eta_eta"
      :rules="validationErrors.eta_eta"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.eta_eta"
        @click="getReferenceParameter(`eta_eta`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-model="uptime"
      :error-messages="fieldErrors.uptime"
      label="Tägliche Betriebsstunden (ohne Absenkbetrieb)"
      :items="uptimes"
      suffix="h"
      :rules="validationErrors.uptime"
      validate-on-blur
    >
      <v-tooltip v-if="hint.uptime" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.uptime }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.uptime"
        @click="getReferenceParameter(`uptime`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-text-field
      style="margin-left:35px"
      v-model.number="daysPerYear"
      type="number"
      :error-messages="fieldErrors.daysPerYear"
      label="an Tagen im Jahr"
      suffix="d"
      :disabled="!uptime"
      :hint="hint.days_per_year"
      :rules="validationErrors.days_per_year"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.days_per_year"
        @click="getReferenceParameter(`days_per_year`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-checkbox
      v-model="drawDownOperation"
      :error-messages="fieldErrors.drawDownOperation"
      label="Absenkbetrieb"
    >
      <v-tooltip v-if="hint.draw_down_operation" slot="append" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.draw_down_operation }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.draw_down_operation"
        @click="getReferenceParameter(`draw_down_operation`)"
        slot="append"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-checkbox>

    <v-text-field
      style="margin-left:35px"
      v-model.number="dailyDrawDown"
      type="number"
      :error-messages="fieldErrors.dailyDrawDown"
      label="Tägliche Betriebsstunden im Absenkbetrieb"
      suffix="h"
      :disabled="!drawDownOperation"
      v-show="drawDownOperation"
      :hint="hint.daily_draw_down"
      :rules="validationErrors.daily_draw_down"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.daily_draw_down"
        @click="getReferenceParameter(`daily_draw_down`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-model="supplyAirTemperature"
      :error-messages="fieldErrors.supplyAirTemperature"
      label="Zulufttemperatur"
      :items="supplyAirTemperatures"
      suffix="°C"
      :rules="validationErrors.supply_air_temperature"
      validate-on-blur
    >
      <v-tooltip v-if="hint.supply_air_temperature" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.supply_air_temperature }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.supply_air_temperature"
        @click="getReferenceParameter(`supply_air_temperature`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="humidityRequest"
      :error-messages="fieldErrors.humidityRequest"
      label="Feuchteanforderung"
      :items="humidityRequests"
      item-text="text"
      item-value="value"
      :rules="validationErrors.humidity_request"
      validate-on-blur
    >
      <v-tooltip v-if="hint.humidity_request" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.humidity_request }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.humidity_request"
        @click="getReferenceParameter(`humidity_request`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="humidifier"
      :error-messages="fieldErrors.humidifier"
      label="Befeuchter"
      :items="humidifiers"
      item-text="text"
      item-value="value"
      :rules="validationErrors.humidifier"
      validate-on-blur
    >
      <v-tooltip v-if="hint.humidifier" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.humidifier }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.humidifier"
        @click="getReferenceParameter(`humidifier`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="heatRecovery"
      :error-messages="fieldErrors.heatRecovery"
      label="Wärmerückgewinnungs- bzw. Umluftsystem"
      :items="heatRecoveries"
      item-text="text"
      item-value="value"
      :rules="validationErrors.heat_recovery"
      validate-on-blur
    >
      <v-tooltip v-if="hint.heat_recovery" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.heat_recovery }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.heat_recovery"
        @click="getReferenceParameter(`heat_recovery`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-text-field
      v-model.number="heatRecoveryCoefficient"
      type="number"
      :error-messages="fieldErrors.heatRecoveryCoefficient"
      label="Wirkungsgrad Wärmerückgewinnung"
      suffix="%"
      :hint="hint.heat_recovery_coefficient"
      :rules="validationErrors.heat_recovery_coefficient"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.heat_recovery_coefficient"
        @click="getReferenceParameter(`heat_recovery_coefficient`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-checkbox
      v-model="coolingRegister"
      :error-messages="fieldErrors.coolingRegister"
      label="Kühlregister"
    >
      <v-tooltip v-if="hint.cooling_register" slot="append" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.cooling_register }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.cooling_register"
        @click="getReferenceParameter(`cooling_register`)"
        slot="append"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-checkbox>
  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";
import { djangoChoicesToVuetify } from "@/utils";
import { cacheAdapter } from "@/cache";

export default {
  name: "RltForm",
  extends: BaseForm,

  data() {
    return {
      amount: null,
      volumeFlow: null,
      volumeFlowDrawDown: null,
      pressureSup: null,
      etaSup: null,
      volumeFlowEta: null,
      volumeFlowDrawDownEta: null,
      pressureEta: null,
      etaEta: null,
      uptime: null,
      daysPerYear: null,
      drawDownOperation: false,
      dailyDrawDown: null,
      supplyAirTemperature: null,
      humidityRequest: null,
      humidifier: null,
      heatRecovery: null,
      heatRecoveryCoefficient: null,
      coolingRegister: false,

      uptimes: [],
      supplyAirTemperatures: [],
      humidityRequests: [],
      humidifiers: [],
      heatRecoveries: []
    };
  },

  computed: {
    fields() {
      let fields = {
        amount: "amount",
        volumeFlow: "volume_flow",
        volumeFlowEta: "volume_flow_eta",

        uptime: "uptime",
        daysPerYear: "days_per_year",
        drawDownOperation: "draw_down_operation",
        supplyAirTemperature: "supply_air_temperature",
        humidityRequest: "humidity_request",
        humidifier: "humidifier",
        heatRecovery: "heat_recovery",
        heatRecoveryCoefficient: "heat_recovery_coefficient",
        coolingRegister: "cooling_register"
      };
      if (this.drawDownOperation) {
        fields = {
          ...fields,
          dailyDrawDown: "daily_draw_down",
          volumeFlowDrawDown: "volume_flow_draw_down",
          volumeFlowDrawDownEta: "volume_flow_draw_down_eta"
        };
      }
      if (!this.sharedData.external_fan_calculation) {
        fields = {
          ...fields,
          pressureSup: "pressure_sup",
          etaSup: "eta_sup",
          pressureEta: "pressure_eta",
          etaEta: "eta_eta"
        };
      }

      return fields;
    },
    etaSupPercentage: {
      get: function() {
        if (!this.etaSup) return null;
        return +(this.etaSup * 100).toFixed(2);
      },
      set: function(newValue) {
        this.etaSup = (newValue / 100).toFixed(4);
      }
    },
    etaEtaPercentage: {
      get: function() {
        if (!this.etaEta) return null;
        return +(this.etaEta * 100).toFixed(2);
      },
      set: function(newValue) {
        this.etaEta = (newValue / 100).toFixed(4);
      }
    }
  },
  beforeMount() {
    this.fetchUptimes();
    this.fetchSupplyAirTemperatures();
    this.fetchHumidityRequests();
    this.fetchHumidifiers();
    this.fetchHeatRecoveries();
  },

  mounted() {
    if (!this.drawDownOperation) {
      this.drawDownOperation = false;
    }
    if (!this.coolingRegister) {
      this.coolingRegister = false;
    }
  },

  methods: {
    fetchUptimes() {
      for (let i = 1; i <= 24; i++) {
        this.uptimes.push(i);
      }
    },

    fetchSupplyAirTemperatures() {
      for (let i = 14; i <= 24; i++) {
        this.supplyAirTemperatures.push(i);
      }
    },

    fetchHumidityRequests() {
      this.$http
        .get("/api/v1/methods/hvac/humidity-requests/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.humidityRequests = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {});
    },

    fetchHumidifiers() {
      this.$http
        .get("/api/v1/methods/hvac/humidifiers/", { adapter: cacheAdapter })
        .then((response) => {
          this.humidifiers = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {});
    },

    fetchHeatRecoveries() {
      this.$http
        .get("/api/v1/methods/hvac/heat-recoveries/", { adapter: cacheAdapter })
        .then((response) => {
          this.heatRecoveries = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped></style>
