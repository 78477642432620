var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.fieldErrors.amount,"label":"Anzahl","hint":_vm.hint.amount,"rules":_vm.validationErrors.amount,"validate-on-blur":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=_vm._n($$v)},expression:"amount"}},[(
        !_vm.reference &&
        this.referenceData.amount
      )?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter(`amount`)}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-autocomplete',{attrs:{"error-messages":_vm.fieldErrors.nominalWidth,"label":"Nennweite","suffix":"mm","items":_vm.widths,"item-text":"width","item-value":"width","rules":_vm.validationErrors.nominal_width,"validate-on-blur":""},model:{value:(_vm.nominalWidth),callback:function ($$v) {_vm.nominalWidth=$$v},expression:"nominalWidth"}},[(_vm.hint.nominal_width)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.nominal_width))])]):_vm._e(),(
        !_vm.reference &&
        this.referenceData.nominal_width
      )?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter(`nominal_width`)}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-checkbox',{attrs:{"error-messages":_vm.fieldErrors.checkNormUptime,"label":"Norm-Betriebsstunden"},model:{value:(_vm.checkNormUptime),callback:function ($$v) {_vm.checkNormUptime=$$v},expression:"checkNormUptime"}},[(_vm.hint.check_norm_uptime)?_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.check_norm_uptime))])]):_vm._e()],1),(!!_vm.checkNormUptime)?_c('v-autocomplete',{attrs:{"label":"Norm-Betriebsstunden","items":_vm.uptimes,"item-text":"name","item-value":"id","return-object":"","rules":_vm.validationErrors.norm_uptime,"validate-on-blur":""},model:{value:(_vm.normUptimeObject),callback:function ($$v) {_vm.normUptimeObject=$$v},expression:"normUptimeObject"}},[(_vm.hint.norm_uptime)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.norm_uptime))])]):_vm._e(),(
        !_vm.reference &&
        this.referenceData.norm_uptime
      )?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter(`norm_uptime`)}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1):_vm._e(),_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.fieldErrors.uptime,"label":"Betriebsstunden","suffix":"h/a","disabled":!!_vm.checkNormUptime,"hint":_vm.hint.uptime,"rules":_vm.validationErrors.uptime,"validate-on-blur":""},model:{value:(_vm.uptime),callback:function ($$v) {_vm.uptime=_vm._n($$v)},expression:"uptime"}},[(
        !_vm.reference &&
        this.referenceData.uptime
      )?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter(`uptime`)}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),(_vm.reference)?_c('v-autocomplete',{attrs:{"error-messages":_vm.fieldErrors.regulation,"label":"Regelung","items":_vm.regulations,"rules":_vm.validationErrors.regulation,"validate-on-blur":""},model:{value:(_vm.regulation),callback:function ($$v) {_vm.regulation=$$v},expression:"regulation"}},[(_vm.hint.regulation)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.regulation))])]):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }