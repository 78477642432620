<template>
  <div>
  <v-row justify="center" no-gutters>
    <v-card>
      <v-card-title class="heading justify-center">
        <h4 class="text-h4">Login</h4>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-form @submit.prevent="login" v-model="valid">
          <v-text-field
            v-model="email"
            type="email"
            :rules="emailRules"
            label="E-Mail"
          />
          <v-text-field
            v-model="password"
            type="password"
            :rules="passwordRules"
            label="Passwort"
          />

          <div class="d-flex flex justify-center mt-4">
            <v-btn type="submit" color="primary" :disabled="!valid"
              >Login</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-row>

  <v-row v-if="attemptedLogin" justify="center" no-gutters style="padding-top: 16px;">
    <a  href="#" @click.prevent="showForgotPasswordDialog = true">Passwort vergessen?</a>
  </v-row>

  <v-dialog v-model="showForgotPasswordDialog" max-width="400px">
    <v-card>
      <v-card-title class="headline">Passwort vergessen</v-card-title>
      <v-card-text>
        Möchten Sie ihr Passwort zurücksetzen lassen?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="proceedWithForgotPassword">Bestätigen</v-btn>
        <v-btn color="grey" text @click="showForgotPasswordDialog = false">Abbruch</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showSuccessDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Passwort vergessen</v-card-title>
        <v-card-text>
          <p>Sie erhalten demnächst ein Ein-Mal-Passwort.</p>
          <p>Um den Prozess zu beschleunigen, können Sie eine E-Mail an 
            <a href="mailto:earns@rom-technik.de">earns@rom-technik.de</a> senden.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="proceedToLogin">Verstanden</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { emailMatch } from "@/utils";

export default {
  name: "Login",
  showSuccessDialog: false,
  data() {
    return {

      attemptedLogin: false,
      showForgotPasswordDialog: false,
      passwordRules: [
        (v) => !!v || "Bitte geben Sie ein Passwort ein um sich einzuloggen."
      ],
      emailRules: [
        (v) =>
          (v && emailMatch(v)) ||
          "Bitte geben Sie eine valide E-Mail Adresse ein um sich einzuloggen."
      ],
      email: null,
      password: null,
      valid: false
    };
  },
  computed: {},
  beforeMount() {
    this.$store.state.forgotPassword = false;
  },
  methods: {
    proceedToLogin() {
      this.showSuccessDialog = false
      this.$router.push({name: "Login"})
    },
    login() {
      this.$store
        .dispatch("login", { email: this.email, password: this.password })
        .then(() => {
          this.$toast("Login erfolgreich.", { color: "success" });
          if(this.$store.state.forgotPassword){
            this.$router.push("/login/forgot-Password");
          }else{
            this.$router.push("/");
          }


        })
        .catch((error) => {
          if (error.response.data.inactive_user){
            this.attemptedLogin = false;
            console.log(error.response.data);
            this.$toast(error.response.data.inactive_user.join(" "),{color: "warning" });
          }
          else if (error.response.data.invalid_password){
            this.attemptedLogin = true;
            console.log(error.response.data);
            this.$toast(error.response.data.invalid_password.join(" "),{color: "error" });
          }
          else {
            console.log(error.response.data);
            this.attemptedLogin = false;
            this.$toast(
            "Falsche E-Mail oder Passwort.", { color: "error" }
          )};
        });
    },
    proceedWithForgotPassword() {
      this.showForgotPasswordDialog = false;
      this.showSuccessDialog = true;
      this.$http.post("/api/v1/auth/login/forgot-password/", { email: this.email })
        .then(() => {
          this.$toast("Passwort vergessen wurde an den Admin gesendet. Sie erhalten demnächst ein Ein-Mal-Passwort.", { color: "success" });
        })
        .catch(() => {
          this.$toast("Passwort vergessen fehlerhaft.", { color: "error" });
        });
    }
  }
};
</script>

<style scoped>
.v-card {
  width: 100%;
  max-width: 400px;
}
</style>
