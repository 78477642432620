
<template>
    <v-row justify="center" no-gutters>
      <v-card>
        <v-card-title class="heading justify-center">
          <h4 class="text-h4">Passwort ändern</h4>
        </v-card-title>
        <v-divider/>
  
        <v-card-text>
          <v-form @submit.prevent="submit" v-model="valid" ref="form">
  
            

            <v-text-field v-model="password" type="password" :rules="passwordRules" label="Neue Passwort" @input="validate"/>
            <v-text-field v-model="password2" type="password" :rules="password2Rules" label="Passwort (wiederholen)" @input="validate"/>
  
            <div class="d-flex flex justify-center mt-4">
              <v-btn type="submit" color="primary" :disabled="!valid">Bestätigen</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </template>
  
  
  
  <script>
  import axios from "axios";
  export default {
    name: "ForgotPassword",
  
    data() {
      return {
        passwordRules: [],
        password2Rules: [],
        requiredRule: [v => !!v || "Diese Feld muss ausgefüllt sein."],
        password: null,
        password2: null,
        valid: false,
  
        passwordErrors: {}
      };
    },
  
    beforeMount(){
      
    },
    methods: {
      fetch(){
       
      }
    },
    mounted(){
      this.password2Rules = [
       ...this.requiredRule,
        v =>
            v && v.length >= 8 || "Das Password muss mindestens 8 Zeichen lang sein.",
        () => this.password === this.password2 || "Die Passwörter stimmen nicht überein.",
  
      ]
  
      this.passwordRules = [...this.password2Rules,
        v => this.passwordErrors[v]?.[0] || true,
      ]
  
    },
    methods: {
      validate() {
        this.$refs.form.validate()
      },
      submit() {
        let payload = { new_password: this.password, confirm_password: this.password2}
        axios.post("/api/v1/auth/login/forgot-password/", payload)
            .then(response => {
              this.$toast("Registrierung erfolgreich.", {"color": "success"})
              this.$store.state.forgotPassword = false
              this.$store.commit("setUser", response.data)
              console.log(response.data)  
              this.$router.push({name: "Home"})
            })
            .catch(error => {
              if (error.response.status === 400 ) {
                if (error.response.data.confirm_password) {
                  this.passwordErrors[payload.confirm_password] = error.response.data.confirm_password
                  this.$toast("Passwoerter stimmen nicht ueberein.", {"color": "error"})
                }
                // this.validate()
                //this.$toast(error.response.data.email[0], {"color": "error"})
              }
              else {
                this.$toast("Es gab einen Fehler beim registrieren.", {"color": "error"})
              }
            })
  
  
      }
    }
  };
  </script>