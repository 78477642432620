<template>
  <v-card>
    <v-row no-gutters>
      <v-card-title class="headline">
        Projekte
      </v-card-title>
      <v-row no-gutters class="justify-end">
        <div>
          <v-container>
            <Helpbar :help="ProjectListHelp" />
          </v-container>
        </div>
      </v-row>
    </v-row>

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Name
            </th>
            <th class="text-right">
              Erstellt
            </th>
            <th class="text-right">
              Option
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in projects"
              :key="item.id"
              @click="onClick(item.id)"
              class="pointer"
          >
            <td>{{ item.name }}</td>
            <td class="text-right">{{ $filters.date(item.created_at) }}</td>
            <td class="text-right">
              
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    title="Optionen"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-dialog
                      v-model="dialogDelete"
                      persistent
                      max-width="290"
                      :retain-focus="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text block
                          @click="storeIdForDialog(item.id)"
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Löschen
                        </v-btn>
                      </template>
                      <v-card>
                        
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="red"
                            text
                            @click="dialogDelete = false"
                          >
                            Abbrechen
                          </v-btn>
                          <v-btn text @click="deleteProject(item.id,item.name);
                                              dialogDelete = false">
                            Löschen
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                    text block
                      @click.stop="copyProject(item.id)"
                      >
                      Kopie erstellen
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-dialog
                      v-model="dialogRename"
                      persistent
                      max-width="600px"
                      :retain-focus="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text block
                          v-bind="attrs"
                          v-on="on"
                        >
                          Umbenennen
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Projekte umbennen</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-text-field
                              label="Name"
                              v-model="newProjectName"
                            ></v-text-field>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="red"
                            text
                            @click="
                              dialogRename = false;
                              newProjectName = null;
                            "
                          >
                            Abbrechen
                          </v-btn>
                          <v-btn
                            text
                            @click="renameProject(item.id);"
                            :disabled="!newProjectName"
                          >
                            Speichern
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>


          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row justify="end" no-gutters class="mt-4">
        <v-btn @click="newAction" color="primary">
          <v-icon left>mdi-plus</v-icon>
          Neues Projekt
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Helpbar from "@/components/Helpbar";
import ProjectListHelp from "@/components/help/ProjectListHelp";

export default {
  name: "ProjectList",
  components: {
    Helpbar,
  },
  data() {
    return {
      ProjectListHelp,
      dialogDelete: false,
      dialogRename: false,
      newProjectName: null,
      
    }
  },
  computed: {
    projects() {
      return Object.values(this.$store.state.allProjects)
    },
  },
  beforeMount() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.$store.dispatch("fetchAllProjects")
    },
    onClick(projectId) {
      this.$router.push({name: "ProjectDashboard", params: {projectId: projectId}})
    },
    newAction() {
      this.$router.push({name: "NewProject", params: {}})
    },
    deleteProject(projectId,name) {
      this.$http
        .delete(`/api/v1/projects/${projectId}/`)
        .then(() => {
           
          this.$toast(
            `Projekt <b>${name}</b> erfolgreich gelöscht.`,
            { color: "success" }
          );
          setTimeout(()=>{this.$router.go(0);},1000);
          
        })
        .catch(() => {
          this.$toast("Es gab einen Fehler beim Löschen des Projekts.", {
            color: "error"
          });
        });
    },
    storeIdForDialog(id) {
      this.dialogId = id;
    },
    renameProject(projectId) {
      this.$http
        .patch(`/api/v1/projects/${projectId}/`, {
          name: this.newProjectName
        })
        .then((response) => {
          this.$toast("Projekt erfolgreich umbenannt.", { color: "success" });
          setTimeout(()=>{this.$router.go(0);},500);
        })
        .catch(() => {
          this.$toast("Projekt kann nicht umbenannt werden.", {
            color: "error"
          });
        });
    },
    copyProject(id){
      this.$http
      .post(`/api/v1/projects/${id}/duplicate/`,{
        projectId: id,
      })
      .then(() => {
        this.$toast("Projekt erfolgreich kopiert.", { color: "success" });
        setTimeout(()=>{this.$router.go(0);},1000);
      })
      .catch(() => {
              this.$toast("Projekt kann nicht kopiert werden.", {
                color: "error"
              });
            });
    },
  }
}
</script>
